// ブックボードを iframe 埋め込みするスクリプト
//
// ### 使い方
//
// 二つのモードがある
//
// - 単体のブックボードを利用する
// - 複数のブックボードを切り替えて使う
//
// #### 単体のブックボードを利用する
//
// ```html
// <div data-bookrun-friendly-key="@zaru60"></div>
// <script src="http://127.0.0.1:3000/assets/js/embed.js"></script>
// ```
//
// #### 複数のブックボードを切り替えて使う
//
// `switch()` メソッドを使うことで複数のブックボードを切り替えられる
//
// ```html
// <select class="selector">
//   <option>選択してください</option>
//   <option value="@zaru60">@zaru60</option>
//   <option value="@zaru30">@zaru30</option>
// </select>
// <script>
//   document.addEventListener('DOMContentLoaded', () => {
//     const selector = document.querySelector('.selector');
//     selector.addEventListener('change', (event) => {
//       const input = event.currentTarget;
//       window.bookrun.switch(input.value);
//     });
//   });
// </script>
// <div class="bookrun" data-autoload="false" data-bookrun-friendly-key="@zaru60"></div>
// <div class="bookrun" data-autoload="false" data-bookrun-friendly-key="@zaru30"></div>
// <script src="http://127.0.0.1:3000/assets/js/embed.js"></script>
// ```
//
// ### ローカルで動作確認する方法
//
// webpacker を実行してビルドするコマンド
// `APP_HOST=127.0.0.1:3000 TZ=utc bundle exec ./bin/webpack --watch --colors --progress`
//
// このコマンドで http://127.0.0.1:3000/assets/js/embed.js に生成される
//
window.bookrun = {
  valuePrefix: 'value_', // 初期値 dataset 判定用の prefix
  load() {
    const bookBoards = document.querySelectorAll('[data-bookrun-friendly-key]');
    bookBoards.forEach((bookBoard: HTMLElement) => {
      const autoload = bookBoard.dataset.autoload;
      if (autoload === 'false') {
        return;
      }
      this.renderBookBoard(bookBoard);
    });
  },
  switch(friendlyKey: string) {
    const selector = `[data-bookrun-friendly-key]`;
    document.querySelectorAll<HTMLElement>(selector).forEach((bookBoard) => {
      bookBoard.style.display = 'none';
    });
    this.show(friendlyKey);
  },
  show(friendlyKey: string) {
    const selector = `[data-bookrun-friendly-key="${friendlyKey}"]`;
    const bookBoard = document.querySelector<HTMLElement>(selector);
    if (bookBoard) {
      bookBoard.style.display = 'block';
      if (bookBoard.dataset.rendered !== 'true') {
        this.renderBookBoard(bookBoard);
      }
    }
  },
  renderBookBoard(bookBoard: HTMLElement) {
    const rendered = bookBoard.dataset.rendered;
    if (rendered === 'true') {
      return;
    }

    window.addEventListener(
      'message',
      (event) => {
        if (event.data.sender !== 'bookrun') {
          return;
        }
        if (event.data.friendlyKey !== bookBoard.dataset.bookrunFriendlyKey) {
          return;
        }
        iframe.height = event.data.height;
      },
      false,
    );

    const friendlyKey = bookBoard.dataset.bookrunFriendlyKey;

    const defaultValues = {
      bookBoardEmbeded: true, // 埋め込みしたかのフラグをサーバに渡す
    };
    Object.keys(bookBoard.dataset).forEach((key) => {
      if (key.startsWith(this.valuePrefix)) {
        defaultValues[key.replace(this.valuePrefix, '')] = bookBoard.dataset[key];
      }
    });
    const queryString = new URLSearchParams(defaultValues as any).toString(); // eslint-disable-line @typescript-eslint/no-explicit-any

    const iframe = document.createElement('iframe');
    iframe.style.border = 'none';
    iframe.width = window.screen.width < 820 ? `100%` : '820';
    iframe.src = `${process.env.APP_HOST}/${friendlyKey}?${queryString}`;

    bookBoard.appendChild(iframe);
    bookBoard.dataset.rendered = 'true';
  },
};

document.addEventListener('DOMContentLoaded', () => {
  window.bookrun.load();
});
